<template>
    <div id="Crew">
        <div class="main">
            <div class="section section-title">
                <div class="title" data-aos="fade-up">
                    <h1>核心團隊</h1>
                    <span>Crew</span>
                </div>
            </div>
            <div class="section section-crew">
                <ul class="person-list">
                    <li
                        v-for="(item, index) in crewData"
                        :key="'Person' + index"
                        class="person"
                        data-aos="fade-up"
                        :data-aos-delay="200 + 200 * index"
                    >
                        <i :class="'iconfont icon-' + item.icon"></i>
                        <h2 class="name">{{ item.name }}</h2>
                        <div class="position">
                            <span
                                v-for="(subitem, subindex) in item.position"
                                :key="'person' + index + 'position' + subindex"
                            >
                                {{ subitem }}
                            </span>
                        </div>
                        <ul class="experience">
                            <li
                                v-for="(subitem, subindex) in item.experience"
                                :key="
                                    'person' + index + 'experience' + subindex
                                "
                            >
                                {{ subitem }}
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <ContactForm></ContactForm>
    </div>
</template>

<script>
import { ContactForm } from '../components'
export default {
    name: 'Crew',
    components: { ContactForm },
    setup() {
        const crewData = [
            {
                name: 'PJ Wang',
                icon: 'pj',
                position: ['Backend Engineer', 'Data Scientist'],
                experience: [
                    'Senior Software Engineer @ Pulxion MedTech.',
                    'System Thinking Coach @ Omplexity',
                    'Design Thinking Coach @ Alpha Team',
                    'Data Scientist @ UNH3O',
                ],
            },
            {
                name: 'YinYin Huang',
                icon: 'yinyin',
                position: ['UI/UX Designer', 'Frontend Engineer'],
                experience: [
                    'Product Manager @ Pulxion MedTech.',
                    'R&D Engineer @ Pulxion MedTech.',
                    'Visual Designer @ NTU Challenge',
                ],
            },
            {
                name: 'Jack Chung',
                icon: 'jack',
                position: ['Project Manager', 'User Researcher'],
                experience: [
                    'Design Thinking Coach @ Alpha Team',
                    'Program Director @ NTU D-School',
                    'Sales Representative @ Createtiles',
                ],
            },
        ]

        return { crewData }
    },
}
</script>

<style lang="scss" scoped>
.section-crew {
    text-align: center;
    li.person {
        width: 100%;
    }
    i.iconfont {
        color: $major-dark;
        font-size: 40px;
    }
    h2.name {
        padding: 30px 0;
        color: $gray-4;
        font-size: 24px;
        font-weight: 600;
    }
    .position {
        color: $major-dark;
        font-style: italic;
        font-size: 14px;
        line-height: 1.3em;
        span {
            display: block;
        }
    }
    ul.experience {
        padding-top: 50px;
        li {
            color: $gray-4;
            font-size: 12px;
            font-weight: 300;
            line-height: 1.3em;
        }
    }
}
</style>

<style lang="scss" scoped>
@media screen and (min-width: 960px) {
    .section-crew {
        ul.person-list {
            display: flex;
            margin: 0 auto;
            width: 90%;
            max-width: 1200px;
        }
        li.person {
            border-right: 1px solid $minor-light;
            &:last-child {
                border-right: none;
            }
        }
    }
}
@media screen and (max-width: 959px) {
    .section-crew {
        li.person {
            margin: 0 auto;
            padding: 50px 0;
            width: 70%;
            max-width: 540px;
            border-bottom: 1px solid $minor-light;
            &:first-child {
                border-top: 1px solid $minor-light;
            }
        }
    }
}
</style>
